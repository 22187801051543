/*
 * @Autor: Haibo Deng
 * @Date: 2022-04-01 20:38:49
 * @Description: 
 * @LastEditTime: 2022-04-05 16:05:29
 * @LastEditors: Haibo Deng
 */
import qs from "qs";
import { service } from "@utils";

const getHomeData = () => service.get("/v1/front/index");
const getDetailData = (params) => service.get("/v1/front/product/list", { params });
const getFilterData = (data) => service.post("/v1/front/product/filter", qs.stringify(data));
const getCatalogList = (params) => service.get("/v1/front/catalog/list", { params });
const getNewData = () => service.get("/v1/front/product/new");
const getSpecialData = (params) => service.get("/v1/front/product/special", { params });
const getSearchData = (data) => service.post("/v1/front/product/search",  qs.stringify(data));
const getSystemData = () => service.get("/v1/front/setting");


export default {
  getHomeData,
  getDetailData,
  getCatalogList,
  getFilterData,
  getNewData,
  getSpecialData,
  getSearchData,
  getSystemData
}