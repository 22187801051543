/*
 * @Autor: Haibo Deng
 * @Date: 2022-04-01 20:40:28
 * @Description: 
 * @LastEditTime: 2022-04-02 01:32:10
 * @LastEditors: Haibo Deng
 */
import axios from "axios";
import { Message } from "element-ui";
// import ls from "./ls";
import router from "@/router/index";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
// axios.defaults.headers.get["Content-Type"] =
//   "application/x-www-form-urlencoded";
// axios.defaults.withCredentials = true

// 创建axios实例
let service = axios.create({
  baseURL: process.env.VUE_APP_API + '/api', // api的base_url
  timeout: 300000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // let token = ls.getKey("token");
    // if (config?.params?.responseType) {
    //   let { responseType, ...other } = config.params;
    //   config.params = other;
    //   config.responseType = responseType;
    // }
    // if (token && !config.url.includes("/login")) {
    //   config.headers = {
    //     token: token, //携带权限参数
    //   };
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code:200,接口正常返回;
     */
    const res = response.data;
    if (response.status !== 200 && res.code !== 0) {
      Message({
        message: res.msg,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject("error");
    } else {
      // res.code === 200,正常返回数据
      return response.data;
    }
  },
  (error) => {
    if (error.response.status === 400 || error.response.status === 401) {
      // Message({
      //     type: 'warning',
      //     message: '登录已过期, 请重新登录'
      // })
      router.replace("/login");
    } else if (error.response.status === 403) {
      router.replace("/403");
    } else {
      Message({
        message: error.response.msg,
        type: "error",
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  }
);

export default service;
