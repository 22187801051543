/*
 * @Autor: Haibo Deng
 * @Date: 2022-04-01 20:50:36
 * @Description: 
 * @LastEditTime: 2022-04-01 20:50:36
 * @LastEditors: Haibo Deng
 */
const ls = {
  getKey(key) {
    return localStorage.getItem(key) || "";
  },
  setKey(key, value) {
    localStorage[key] = value;
  },
  getObj(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      return "";
    }
  },
  setObj(key, obj) {
    try {
      localStorage[key] = JSON.stringify(obj);
    } catch (error) {
      localStorage[key] = "";
    }
  },
  clear() {
    localStorage.clear();
  },
};

export default ls;